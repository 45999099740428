@import '../../variables';

.Header {
  .header-search {
    box-shadow: none;
  }

  .left-navbar-container {
    align-items: center;
    height: 46px;
    display: flex;

    .logo {
      object-fit: cover;
      height: 50%;
      width: 50%;
    }
    .sponsor {
      border-left: 1px solid $gray-500;
      margin-left: 15px;
      padding-left: 15px;
    }

    @include media-breakpoint-up(md) {
      .logo {
        height: 46px;
        width: 263px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .left-navbar-container {
      width: 80%;
    }
  }

  @include media-breakpoint-down(xs) {
    .left-navbar-container {
      width: 65%;
    }
  }

  .right-navbar-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .mobile-sponser {
    text-align: center;
  }

  .dropdown-item {
    color: $link-color;

    &:hover {
      text-decoration: underline;
    }
  }
}
