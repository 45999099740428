@import '../../variables';

.SearchBoxMobile {
  .SearchBoxMobile--button {
    border: 0;
    color: $white;
    cursor: pointer;

    &:active,
    &:hover {
      background-color: $primary !important;
    }
  }
}

.SearchBoxMobile--modal {
  margin: 0 !important;
  max-width: 100% !important;
  height: 100%;

  .searchbox-container {
    position: fixed;
    width: 100%;
    z-index: $zindex-modal;
  }

  .SearchBox {
    .SearchBox--input {
      border-radius: 0;
    }
  }

  .modal-close-btn {
    background-color: $white;
    border: 0;
    color: $gray-600;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    right: 8px;
    top: 0;
    z-index: $zindex-modal;

    @include media-breakpoint-up(md) {
      top: 5px;
    }
  }

  .modal-content {
    border: 0;
    background-color: rgba($white, 0.9);
    height: 100%;
  }

  .ResultsList {
    margin-top: 60px;
    li:first-child {
      border-top: 0;
    }
    li:last-child {
      border-bottom: 0;
    }

    .SearchBoxMobileResultItem,
    .list-group-item {
      background-color: transparent;
      border: 1px solid $gray-300;
      padding: 20px;

      h4 {
        margin: 0;
      }

      &,
      .media {
        color: $gray-650 !important;
      }
    }
  }
}
