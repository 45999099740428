@import '../../variables';

.HelpPage {
  h2.toc-level-0,
  h2.toc-level-1 {
    font-size: 1.875em !important;
    color: $secondary !important;
    font-weight: $font-weight-base !important;
    font-family: $font-family-base !important;
  }
  h3.toc-level-2 {
    color: $global-text-color !important;
    font-weight: $font-weight-base;
    font-size: 1.5em;
    font-family: $font-family-base;
  }
  h3.toc-level-3,
  h3.toc-level-4,
  h3.toc-level-5,
  h3.toc-level-6 {
    color: $global-text-color !important;
    font-weight: $font-weight-base;
    font-size: 1.25em !important;
    font-family: $font-family-base;
  }

  p {
    line-height: $line-height-lose !important;
  }

  .back-button {
    font-size: inherit;
    text-shadow: none;
    font-size: 1rem;
  }
  .BrowseThemePage--resutls-list-col,
  .ResultsList {
    @include media-breakpoint-up(md) {
      height: 500px;
    }
  }

  .BrowseThemePage--structure-list-col {
    .list-group {
      @include media-breakpoint-up(md) {
        overflow: auto;
      }
      .list-group-item {
        &.has-children {
          border-left: 2px solid $green;
        }
        &.no-children {
          border-left: 2px solid $orange;
        }
      }
    }
  }

  .BrowserThemePage--mobile-navigation {
    .full-width {
      text-align: left;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
      .open-menu,
      .closed-menu {
        position: absolute;
        right: 0.75rem;
      }
      &.closed {
        .open-menu {
          display: none;
        }
      }
      &.open {
        .closed-menu {
          display: none;
        }
      }
    }
    .card {
      border-radius: 0;
      &.has-children {
        border-left: 2px solid $green;
      }
      &.no-children {
        border-left: 2px solid $orange;
      }
      &:last-child {
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
      }
    }
  }
}
