@import '../../variables';

.SearchWidgetWrapper {
  background-color: $brand-primary;
  padding-bottom: 10px;
  .logo {
    max-width: 310px;
  }
  .SearchBox {
    margin: 0 20px;
  }
}
