// Line height utility classes
// For 'normal' just don't add a class.

.lh-tight {
  line-height: $line-height-tight !important;
}
.lh-lose {
  line-height: $line-height-lose !important;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.disabled_txt {
  color: $gray-550 !important;
}

// TODO Map through for all colors.
.text-color--secondary {
  color: $secondary !important;
}

.text-color--natural-text {
  color: $global-text-color !important;
}
.text-color--gray-600 {
  color: $gray-600 !important;
}
.text-color--light {
  color: $light !important;
}
.text-color--dark {
  color: $dark !important;
}
.text-shadow--contrast {
  text-shadow: 0 1px 1px rgba(0, 1px, 1px, 0.75);
}

.height--auto {
  height: auto !important;
}
