@import '../../variables';

.BrowseThemePage {
  .back-button {
    font-size: inherit;
    text-shadow: none;
    font-size: 1rem;
  }
  .BrowseThemePage--section {
    .BrowseThemePage--resutls-list-col {
      .ResultsList {
        .Loading {
          background: $white !important;
          padding-top: 50px;
        }
      }
    }
  }

  .BrowseThemePage--resutls-list-col {
    @include media-breakpoint-up(md) {
      min-height: 100px !important;
      max-height: 500px !important;
    }
  }
  .BrowseThemePage--resutls-list-col {
    .ResultsList {
      .Loading {
        background: $white !important;
        padding-top: 50px;
      }
    }
  }

  .BrowseThemePage--nav {
    display: flex;
    background-color: $white;
    text-align: center;
    margin: 0 auto;
    padding: 15px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    font-weight: 700;
    align-items: flex-center;
    .nav-item {
      flex-basis: auto;
    }
    .active {
      color: $orange;
    }
  }
  .BrowserThemePage--mobile-navigation {
    .full-width {
      text-align: left;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
      .open-menu,
      .closed-menu {
        position: absolute;
        right: 0.75rem;
      }
      &.closed {
        .open-menu {
          display: none;
        }
      }
      &.open {
        .closed-menu {
          display: none;
        }
      }
    }
    .card {
      border-radius: 0;
      &.has-children {
        border-left: 2px solid $green;
      }
      &.no-children {
        border-left: 2px solid $orange;
      }
      &:last-child {
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
      }
    }
  }
}
