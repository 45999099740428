@import '../../variables';

.Footer {
  .footer-branding-container {
    .nav-link {
      display: inline-block;
      padding: 0;
    }
    .sponsor {
      border-left: 1px solid $gray-500;
      margin-left: 15px;
      padding-left: 15px;
      width: 140px;
    }
  }

  .footer-links-container {
    font-size: 11px;
    margin-top: $spacer;
    a {
      color: rgba(255, 255, 255, 0.75);
      &:hover {
        color: $white;
      }
    }
    @media screen and (min-width: 514px) {
      margin-top: 0;
    }
  }

  .footer-links {
    li:first-child a {
      padding-left: 0;
    }

    li:last-child a {
      padding-right: 0;
    }
  }
}
