@import './variables';

// Global classes
// .escola-funky-heading {
//   color: $yellow;
//   font-family: 'Ubuntu';
//   text-shadow: -2px -2px 0 #808080, 2px -2px 0 #808080, -2px 2px 0 #808080,
//     2px 2px 3px #808080;
// }

::selection {
  background: $lite-blue;
}
a[disabled] {
  color: $gray-550;
}

.escola-custom-select {
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBjb2xvcj0id2hpdGUiIGFyaWEtaGlkZGVuPSJ0cnVlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImNoZXZyb24tZG93biIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWNoZXZyb24tZG93biBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTIwNy4wMjkgMzgxLjQ3NkwxMi42ODYgMTg3LjEzMmMtOS4zNzMtOS4zNzMtOS4zNzMtMjQuNTY5IDAtMzMuOTQxbDIyLjY2Ny0yMi42NjdjOS4zNTctOS4zNTcgMjQuNTIyLTkuMzc1IDMzLjkwMS0uMDRMMjI0IDI4NC41MDVsMTU0Ljc0NS0xNTQuMDIxYzkuMzc5LTkuMzM1IDI0LjU0NC05LjMxNyAzMy45MDEuMDRsMjIuNjY3IDIyLjY2N2M5LjM3MyA5LjM3MyA5LjM3MyAyNC41NjkgMCAzMy45NDFMMjQwLjk3MSAzODEuNDc2Yy05LjM3MyA5LjM3Mi0yNC41NjkgOS4zNzItMzMuOTQyIDB6Ij48L3BhdGg+PC9zdmc+)
    right 7px center no-repeat;
  background-size: 15px;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.jw-icon-cast google-cast-launcher {
  -webkit-appearance: none;
}

.btn {
  @each $color in $btn-colors {
    &.btn-cust-#{nth($color,1)} {
      @include button-variant(nth($color, 2), nth($color, 2));
      @include button-variant-custom-disabled(nth($color, 2), nth($color, 2));
    }
    &.btn-outline-cust-#{nth($color,1)} {
      @include button-outline-variant(nth($color, 2));
      @include button-outline-custom-variant(nth($color, 2));
    }
    &.btn-link-cust-#{nth($color,1)} {
      @include button-link-custom-variant(nth($color, 2));
    }
  }
}

.list-group-item--can-highlight:hover {
  background-color: $quarternary-highlight !important;
}
.list-group-item--can-highlight.active:hover {
  background-color: $secondary !important;
}

// Sticky footer
html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;

  main {
    flex: 1 0 auto;
  }

  footer {
    flex: none;
  }
}

input[type='search'] {
  text-overflow: ellipsis;
}

/* 
  Devices with no hover state should show the abbreviation
  followed by the full text. EMF-439
*/

abbr[title]:after {
  content: ' (' attr(title) ')';
}

@media (hover: hover) {
  abbr[title] {
    cursor: help;
  }

  abbr[title]:after {
    content: '';
  }
}
