@import '../../variables';

.ResultsList {
  height: 100%;
  overflow: auto;

  .resultsList--spinner {
    text-align: center;
  }

  .ResultsList--item {
    padding: 20px;

    p {
      margin: 0;
    }
  }

  .ResultsVideoItem {
    .media-body {
      @include media-breakpoint-down(xs) {
        margin-top: 15px;
      }
    }

    .ResultsVideoItem--title {
      cursor: pointer;
    }

    .ResultsVideoItem--item-caption {
      @include media-breakpoint-only(md) {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 100px;

        &::after {
          content: '..';
          position: relative;
          left: 0;
        }
      }
    }
    .Thumbnail-video-util-wrapper {
      min-height: 70px;
    }
  }
  .media-heading {
    color: $quarternary;
    font-size: 1.5rem;
  }
}
